.aaaa img {
    width: 100%;
    height: 100vh;

;
}
.wwww{
    width: 100%;
    float: left;
    position: relative;
}
.sss{
    display:flex;
}