
  .kapatt a{
    text-decoration: none;
    list-style: none;
    color: white;
    background: #E0BC69;
    padding: 5px;
  }
  .buut button{
    background: rgba(245, 222, 179, 0);
    border: 0px;
    color: #E0BC69;
    border: 1px solid;
    padding: 6px;
    padding-left: 20px;
    padding-right:20px;
  }
  @media only screen and (min-width: 1000px) {
    .gizlilikyazi{
      padding: 100px;
    }
  }
  @media only screen and (max-width: 999px) {
    .gizlilikyazi{
     padding-top: 150px;
     
    }
  }
  @media only screen and (max-width: 999px) {
    .scrol{
      width: 100%;
      height: 300px;
      overflow-y: scroll;
      scrollbar-color: rgb(245, 245, 245) green;
      scrollbar-width: none;
    }
  }

  
  .gizlilikyazi h5{
    color: white;
    margin-top: 50px;
    text-align: center;
    margin-bottom: 20px;
  }
  .popup-overlay{
    background: rgba(0, 0, 0, 0.564)!important;
  }
  .gizlilikyazi p{
    text-align: center;
    color: white;
    margin-top: 5px;
    font-family: mulish;
  }
  .gizlilikyazi li{
    text-align: center;
    color: white;
    list-style: none;
  }
  
  .kapatreklam a{
    text-decoration: none;
    top: 0px;
  }

  @media only screen and (max-width: 990px) {
    .yooutubee{
      margin-top: 25%;
    }
}
@media only screen and (min-width: 991px) {
  .yooutubee{
    margin-top: 10%;
  }
}
.bestavanmobiil img{
   width: 100%;
   padding-top: 36%;
}