.linklere{
    position: fixed;
    right: 2px;
    border-radius: 50px;
    border: 1px solid #e0bc69;
    z-index: 999;
    background: #1B1F22;
    padding: 7px;
    margin-top: 20%;
}
.linklere h3{
    color: #e0bc69;
   
    
}
.linklere a{
    color: #e0bc69;
   
    
}
.arkah3 a{
    background: #e0bc69;
    color: rgb(0, 0, 0)!important;
    padding-bottom: 3px;
  
}
