.gorsel{
    padding-top: 200px;
}
.gorsel h2{
padding: 20px;
font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}
@media only screen and (max-width: 999px) {
  .homemobil{
    background: #1C1C1E;
  }
}
.ff{
  margin-top: 5px;
}
.textform {
  height: 50px;
}

  .mobilanasayfa ul{
    padding-left: 1rem!important;
    margin-top: 8px;
  }
.mobilanasayfa li{
  list-style: none;
  text-align: center;
  font-family: Cambria;
  color: white;
}
.telefon h3{
  background: #E0BC69;
  padding: 6px;
  width: 200px;
  font-family: Cambria;
 
}
.ff input{
  width: 100%;
}
.label{
  display:contents!important;

}

@media only screen and (min-width: 1000px) {
  .desadas{
    float: left;
    padding-bottom: 10px;
  }}
@media only screen and (max-width: 999px) {
  .desadas{
    display: inline-flex;
  
  }}

@media only screen and (max-width: 999px) {
.desadas p{
  text-align: left;

}}
@media only screen and (min-width: 1000px) {
  .popiletisim{
    padding: 20px;
    background: white;
    width: 600px;
    position: absolute;
      /* bottom: -54px; */
      z-index: 10000;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 200px;
      animation-name: logoo;
      animation-duration: 3s;
      border: 15px solid #E0BC69;
  }
}
@media only screen and (max-width: 999px) {
  .popiletisim{
    padding: 10px;
    background: white;
    width: 95%;
    position: absolute;
    
      z-index: 10000;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 50px;
      animation-name: logoo;
      animation-duration: 3s;
      border: 10px solid #E0BC69;
  }
}

.gorselhepsi{

    object-fit: cover;
    height: 100vh;
    width: 100%;
    bottom: 0;
    /* position: absolute; */
    top: 0;
    background: #978877;
}

@media only screen and (max-width: 999px) {
  .ilet{
     
    background: url('../../../public/images/mobil-bg.jpg');
     
    }
}
@media only screen and (max-width: 999px) {
  .iletisimresim img{
    
     width: 100%;
     
    }
}
@media only screen and (min-width: 1000px) {
  .iletisimresim img{
     
     display: none;
     
    }
}
@media only screen and (min-width: 1000px) {
  .ilet{ 
    background: url(../İletisim/images/iletisimsayfası.png);
    height: 100vh;
    padding-top: 16%;
}
}

@media only screen and (min-width: 1000px) {
  .iletbox{
    display: flex;
      justify-content: flex-end;
      margin-right: 10%;
    
  }}
  @media only screen and (max-width: 999px) {
    .iletbox{
      display: none;
    }}


.iletisimyazi p{
  color: #E0BC69;
  
  font-family: Mulish;
  
}




.buto button{
    float: right;
    padding: 20px;
    margin-top: 10px;
    background: #005086;
    border: 0px;
    margin-bottom: 10px;

}
.form-control{
  border: 2px solid black;
}
.form-group p{
  float: left;
    color: rgb(3, 3, 3);
    padding: 0px;
    margin-bottom: 0rem!important;
}
.formaciklama p{
  float: left;
    color: rgb(3, 3, 3);
    padding: 0px;
    margin-bottom: 0rem!important;
}
.mobillform{
  
  padding: 5px;
}
.arkaplanform{
  background: white;
  border: 10px solid black;
}
.padi{
  padding: 40px;
}

.bbbe{
  padding-top: 10px;
}
.bbbe button{
  width: 100%;
  background: #000000f0 !important; 
  border: 0px;
  
}
.desadasx{
  float: left;
  display: flex;
}
.desadasx p{
  font-family: Mulish;
  font-size: 12px;
}
.bbb{
  margin-bottom: 10px;
}

.bbb button{
  width: 100%;
  margin-top: 20px;
  margin-bottom: .1rem !important;
  background: #75757500 !important; 
  border: 1px solid white;
  
} 
.foorrmm{
  padding: 20px;
}
.bb h4{
   margin-bottom: 0.1rem!important;
}

.btn-primary{
  border-color: 1xp solid #3c3e3f !important;
}



.form-group h6{
    float: left;
    color: white;
}
.formaciklama{
    color: white;
    float: left;
    padding-top: 9px;
}
.contact-form{
    padding: 20px;
}

@media only screen and (min-width: 1000px) {
    .mobilanasayfa {
      display: none;
    }
  }
  @media only screen and (max-width: 999px) {
    .mobilanasayfa img {
      width: 100%;
     
      margin-top: 50px;
    }
  }
  
  .mobillform{
      margin-top: 30px;
  }
  @media only screen and (min-width: 1000px) {
    .mobillform {
      display: none;
    }
  }

  
  
  