
@media only screen and (min-width: 999px) {
	.tags {
		text-align: center;
		padding: 20px 0px;
		background-color: rgb(47 39 31);
		margin-top: 180px;
	}
  }
@media only screen and (max-width: 1000px) {
	.tags {
		text-align: center;
		padding: 20px 0px;
		background-color: rgb(47 39 31);
		margin-top: 50px;
	}
  }
  .tag:hover{
	border-bottom: 1px solid #E0BC69;
	
  }
.tag {
	outline: none;
	border: none;
	color: #E0BC69;
	margin: 0px 10px;
	font-size: 30px;
	
	background-color: transparent;
display: block;
}
@media only screen and (min-width: 1000px) {
	.sekil img{
		position: absolute;
		left: 41px;
		right: 0;
		margin-right: auto;
		top: 9%;
		width: 15%;
  
	}
	}

@media only screen and (max-width: 1000px) {
	.sekil img{
		display: none;
	}
	}

.active {
	font-weight: bold;
	border-bottom: 1px solid #eee;
}
@media only screen and (max-width: 1000px) {
	.containerr {
		width: 80%;
		margin: auto;
		display:block;
		grid-template-columns: repeat(4, 1fr);
		gap: 10px;
	}
  }
  @media only screen and (min-width: 999px) {
	.containerr {
		width: 80%;
		margin: auto;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 10px;
		float: left;
		margin-top: 20px;
		
	}
  }
  @media only screen and (max-width: 1000px) {
	.secenekler{
		display: grid;
		margin-top: 30px;
	   
	}
	}
	@media only screen and (min-width: 1000px) {
		.secenekler{
			display: block;
			margin-top:7%
		   
		}
		}
 


  @media only screen and (max-width: 1000px) {
	.sekil img{
		display: none;
	}
	}
.gorseltum{
	padding-top: 15%;
}

.image-card {
	padding: 2px;

}

.image {
	width: 100%;
}
