@media only screen and (min-width: 1000px) {
  .upbar{ 
    background: #1C1C1E;
    width: 100%;
    height: 60px;
    position: absolute;
    top: 0px;
    z-index: 10;
  }
}
@media only screen and (max-width: 1000px) {
  .upbar{ 
    display: none;
  }
}
.bb:hover{
  margin-top: 14px!important;
  float: left;
  color: rgb(255, 255, 255);
  background: #E0BC69;
  padding: 3px;
  /* border-left: 1px solid; */
  /* border: 1px solid; */
  border-right: 3px solid black;
  transition: 0.5s;
}
.bb{
  margin-top: 14px!important;
    float: left;
    color: black;
    background: #E0BC69;
    padding: 3px;
    /* border-left: 1px solid; */
    /* border: 1px solid; */
    border-right: 3px solid;
}
@media only screen and (max-width: 1000px) {
  .info{
    display: none;
  }
 
}
@media only screen and (min-width: 1000px) {
  .info{
    background: #1C1C1E;
    height: 100vh;
  }
}
@media only screen and (min-width: 1000px) {
  .infoo{
    display: none;
  }
}
@media only screen and (max-width: 1000px) {
  .infoo{
    background: #1C1C1E;
    height: 100vh;
  }
 
}.infovideoo{
  padding-top: 30%;
}

.infovideo{
  padding-top: 10%;
}
.inforesim{
  padding-top: 20px;


}
.inforesim img{
  width: 10%;
  animation-name: example;
  animation-duration: 6s;

}
.inforesimm{
  padding-top: 20px;


}
.infovideoo img{
  width: 100%;
}

.inforesimm img{
  width: 20%;
  animation-name: example;
  animation-duration: 6s;

}
@keyframes example {
  from {opacity:0 ;}
  to {opacity:1;}
}
.sagup:hover{

  color: white;
  margin-top: 14px!important;
  transition: 0.5s;
}
.sagup{
  float: right;
  color: #E0BC69;
  margin-top: 14px!important;
}
.solup:hover{
  margin-top: 14px!important;
  float: left;
  color: rgb(255, 255, 255);
 background:#E0BC69 ;
 padding: 3px;
 transition: 0.5s;
}
.solup{
  margin-top: 14px!important;
  float: left;
  color: black;
 background:#E0BC69 ;
 padding: 3px;
}

.logo img{
  position: absolute;
  /* bottom: -54px; */
  z-index: 10000;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 12%;
  animation-name: logoo;
  animation-duration: 3s;
}
@keyframes logoo {
  from {top: -150px;}
  to {top: -0px;}
}
@media only screen and (min-width: 450px) {
  .mobillogo img{
    position: absolute;
    /* bottom: -54px; */
    z-index: 10000;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    animation-name: logoo;
    animation-duration: 3s;
  }
}
@media only screen and (max-width: 449px) {
  .mobillogo img{
    position: absolute;
    /* bottom: -54px; */
    z-index: 10000;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 33%;
    animation-name: logoo;
    animation-duration: 3s;
  }
}

.mobilupcontainer{
  height: 100px;
}
.mobilsagkamera p{
  padding-top: 12px;
  padding-left: 30px;
  float: left;
  color: #E0BC69;
  font-size: 26px;
}

@media only screen and (max-width: 999px) {
  
}

@media only screen and (min-width: 1000px) {
  .mobilupbar{
    display: none;
  }
}
.ee{
  float: left;
  padding-top: 10px;
}
.btn-success {
  color: #fff;
  background-color: #19875400 !important;
  border-color: #19875400 !important;
  color: #E0BC69;
}
.altlink{
  padding: 5px;
}
.altlink :link{
  padding: 3px;
}