
.loftarka{
  background: url('../../../public//images/bg.jpg');
  object-fit: cover;
  height: 100vh;
  width: 100%;
  bottom: 0;
  
  top: 0;
  
}
.tumsayfaloft{
  padding-top: 13%;
  animation-name: tumsayfa;
  animation-duration: 3s;
}
@keyframes tumsayfa {
  from {opacity: 0.3;}
  to {opacity: 1;}
}
@media only screen and (max-width: 1000px) {
  .tumsayfaloft{
    display: none;
  }
}
@media only screen and (min-width: 999px) {
  .loftmobill{
    display: none;
  }
}

.loftmobil{
  display: none;
}
.anabaslik{
  padding-top: 10px;
  display: flex;
  justify-content: center;
  column-gap: 15px;
  padding-bottom: 20px;
  
}
.anabaslikmobil{
  padding-top: 10px;
  display: flex;
  justify-content: center;
  
  padding-bottom: 20px;
  
}
@media only screen and (min-width: 400px) {
  .anabaslikmobil p{
    color: #E0BC69;
   padding-bottom: 7px;
   padding-top: 5px;
  font-size: 25px;
   
    -webkit-border-radius: 20%; 
    border-bottom: 2px solid #E0BC69 ;
  }
}
@media only screen and (max-width: 399px) {
  .anabaslikmobil p{
    color: #E0BC69;
   padding-bottom: 7px;
   padding-top: 5px;
  font-size: 19px;
   -webkit-border-radius: 20%; 
   border-bottom: 2px solid #E0BC69 ;
  }
}
.anabaslikmobil p:hover{
  transform: 1s;
  color: white;
  padding: 10px;
  -webkit-border-radius: 20%; 
  border-bottom: 2px solid #E0BC69 ;
}
.anabaslikmobil button{
  border: 0px;
  background: rgba(255, 255, 255, 0);
}

.loftvideo{
  color: #E0BC69;
  margin-top: 15px;
  width: 300px;
  padding: 5px;
 
 
}
.saglof{
  border-right: 1px solid #E0BC69  ;
  
  
}
.anabaslik h5{
  font-family: Cambria;
  color: #E0BC69;
  padding: 10px;
  -webkit-border-radius: 20%; 
  border-bottom: 2px solid #E0BC69 ;
}
.anabaslik h5:hover{
  font-family: Cambria;
  transform: 1s;
  color: white;
  padding: 10px;
  -webkit-border-radius: 20%; 
  border-bottom: 2px solid #E0BC69 ;
}
.ayrintiloft img{
  width: 46%;
}
.loftcolbir{
 
  position: absolute;
  left: 0px;
  right: 0;
  margin-right: auto;
  top: 9%;
}
.loftcolbir img{
  width: 15%;
}

.anabaslik button{
  border: 0px;
  background: rgba(255, 255, 255, 0);
}.wesd h5{
  color: #E0BC69;
}
.wesd h3{
  color: #E0BC69;
  padding-left: 100px;
  padding-right: 100px;
}
@media only screen and (max-width: 1300px) {
  .ayrintiloft h4{
    padding-left: 60px;
    padding-right: 60px;
    font-family: Cambria;
    color: white;
    
  }
}
@media only screen and (min-width: 1300px) {
  .ayrintiloft h4{
    padding-left: 120px;
    padding-right: 120px;
    font-family: Cambria;
    color: white;
    
  }
}

::-webkit-scrollbar {
  display: none;
  visibility: hidden;
  opacity: 0
}
@media only screen and (min-width: 1300px) {
  .ayrintiloft p{
    color: #E0BC69;
    padding-left: 120px;
    padding-right: 120px;
    font-family: Mulish;
    font-size: 24px;
    
  }
}
@media only screen and (max-width: 1300px) {
  .ayrintiloft p{
    color: #E0BC69;
    padding-left: 80px;
    padding-right: 80px;
    font-family: Mulish;
    font-size: 24px;
    
  }
}

.ayrintiloftmobil h4{
  color: white;
}
.ayrintiloftmobil img{
  width: 100%;
}
.sagloft img{
  width: 90%;
  float: left;
  margin-left: 10px;
  max-height: 500px;


}
@media only screen and (max-width: 1300px) {
  .wesd{
    overflow-y: scroll;
    width: 100%;
    max-height: 200px;
      scrollbar-color: rgb(245, 245, 245) green;
      scrollbar-width: none;
  
  }
}
@media only screen and (min-width: 1300px) {
  .wesd{
    overflow-y: scroll;
    width: 100%;
    max-height:300px;
      scrollbar-color: rgb(245, 245, 245) green;
      scrollbar-width: none;
  
  }
}

.sagloftmobil img{
  width: 100%;
 
}