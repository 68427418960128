.swiper {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 999px) {
    .mySwiperr img {
      object-fit: cover;
      height: 300px;
      width: 100%;
     
      }
  }
 
  @media only screen and (min-width: 1000px) {
    .mySwiperr img {
      object-fit:initial ;
      height: 100vh;
      width: 100%;
      padding-top: 60px;
      bottom: 0;
      /* position: absolute; */
     
      }
  }
  
 


@keyframes whatsaapicon {
    10% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}
@media only screen and (min-width: 1000px) {
  .esa{
    position: absolute;
    right: 30px;
    border: 1px solid rgba(0, 0, 0, 0);
    background-color:green;
    z-index: 9999!important;
    color: green;
    
    -moz-border-radius: 50%; 
    -webkit-border-radius: 50%; 
    border-radius: 50%;
    width: 92px; 
    height: 92px; 
    margin-top: -10%;
    
  
  
  } }
  @media only screen and (max-width: 999px) {
    .esa{
     display: none;
    
    
    } }
  





  
  @media only screen and (min-width: 1000px) {
    .whatsaapicon{
      animation: whatsaapicon 0.8s ease-in-out infinite alternate;
      color: rgb(248, 248, 248);
      font-size: 59px;
      margin-bottom:15px;
      
    
    } }



   @media only screen and (max-width: 999px) {
    .whatsaapicon{
     display: none; 
  
    } }
  .sosyalmedyaiconfacebook{
   
    color: rgb(50, 12, 138);
   
  }
  .sosyalmedyaiconinstgram{
    
    
    color:#fc5c10;
    
  }
  .sosyalmedyaiconyoutube{
    
    color: rgb(156, 10, 10);
    
  }
  @media only screen and (min-width: 1000px) {
  .sosyalmedya{
    position: fixed;
    left: 2px;
    border: 1px solid #e0bc69;
    
    z-index: 9999!important;
    background: black;
    border-radius: 50px;
    
    
    
    width: 41px; 
    
    margin-top: -2%;
  } }
  @media only screen and (max-width: 999px) {
    .sosyalmedya{
    
      position: fixed;
      left: 2px;
      border: 1px solid #e0bc69;
      
      z-index: 9999!important;
      background: black;
      border-radius: 50px;
      
      
      
      width: 41px; 
      
      margin-top: -20%;
    } }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  
  
 
  

 
  
