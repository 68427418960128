.yer {
  padding-top: 15%;
}

.profile-pic {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 20%;
  margin-top: 50px;
}
@media only screen and (max-width: 599px) {
  .lofthakki h2{
    padding-top: 15%;
    color: #E0BC69;
    font-family: Cambria;
  }
}
@media only screen and (max-width: 999px) and (min-width: 600px) { 
  .lofthakki h2{
    padding-top: 20%;
    color: #E0BC69;;
    font-family: Cambria;
  }
}
@media only screen and (max-width: 1499px) and (min-width: 1000px) { 
  .lofthakki h2{
    padding-top: 25%;
    color: #E0BC69;
    font-family: Cambria;
  }
}

@media only screen and (min-width: 1500px) {
  .lofthakki h2{
    padding-top: 30%;
    color: #E0BC69;
    font-family: Cambria;
  }
}
@media only screen and (max-width: 1499px) and (min-width: 1000px) { 
  .testdeneme h3{
    color: #E0BC69;
    font-family: Cambria;
  }
}

  .testdenemee {
    color: #E0BC69 !important;
    font-family: Cambria !important;
    text-align: center !important;
  }

  @media screen and (min-width: 900px) {
    .ilkk {
      padding-left: 200px;
      padding-right: 200px;
      text-align: left;
      color: white;
      font-family:Cambria;
    }
}
@media screen and (max-width: 899px) {
  .ilkk {
   
    text-align: left;
    color: white;
    font-family:Cambria;
  }
}
.peer{
  color: white;
  text-decoration: underline;
}
#a{
  text-decoration: inherit!important;
}


.adenemers a{
  
  text-decoration: inherit !important;
}
.adeneme{
  color: white !important;
 
}

.lofthakki li{
  color: white;
  list-style-type:none;
  font-family: Mulish;
  text-align: left;
}
@media only screen and (min-width: 999px) {
  .lofthakki{
  
    padding-left: 200px;
    padding-right: 200px;
  }
}

.address {
  text-align: left;
  
  
}
.address b {
  color: white;
}
.address p {
  color: white;
}
.contact-details h2{
  color: white;
}
.contact-details b{
  color: white;
}

.contact-details {
  width: 100%;
  text-align: left;
  margin-top: 50px; 
  padding: 15px;
  background: rgba(0, 0, 0, 0.384);
}

