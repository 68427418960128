
.yazi {
    display: inline-flex;
    column-gap: 20px;
    margin: auto;
    line-height: 60px
}
.Gizlilik {
    line-height: 50px;
    
    position: absolute;
    right: 26px;
    bottom: 9px;
}
.offcanvas-end{
    width: 100%!important;
}
.logolar{
    position: absolute;
    bottom: 10px;
}
.logolar img{
    width: 100%;
    padding-left: 10px;
}

.yazi h5 {
    color: black;
    line-height: 50px;
    
    transition: 0.5s;

}

.yazi h5:hover {
    color: #1c1815;
    
    transition: 0.5s;
}

@media only screen and (max-width: 999px) {
    .navbarr {
        display: none;
    }
}
.navbarr {
    background:#E0BC69;
    width: 100%;
    z-index: 11;
    position: absolute;
    bottom: 0px;
    animation-name: nav;
  animation-duration: 2s;
}
@keyframes nav {
    from {opacity: 0.3; }
    to {opacity: 1;}
  }
.mobilfilm{
    width: 200px;

    margin: -15px;
    background: rgb(2, 0, 36);
    background: linear-gradient(151deg, rgba(2, 0, 36, 0.7931547619047619) 0%, rgba(209, 151, 16, 1) 30%, rgba(210, 143, 11, 1) 70%, rgba(4, 0, 11, 0.7847514005602241) 100%);
    border-radius: 20px 50px;
    padding: 1px;
    padding-top: 3px;
    margin-top: 10px;

}

.Biligtalep{
    position: absolute;
    bottom: 55px;
    z-index: 10000;
    
    left: 0; 
    right: 0;
     margin-left: auto; margin-right:auto;
  
    
}
.bilgiyazi {
    position: absolute;
    color: #E0BC69;
    bottom: 58px;
    z-index: 90000;
    margin-left: 47%;
    
}
  

.reklam {


    width: 200px;

    margin: -15px;
    background: rgb(2, 0, 36);
    background: linear-gradient(151deg, rgba(2, 0, 36, 0.7931547619047619) 0%, rgba(209, 151, 16, 1) 30%, rgba(210, 143, 11, 1) 70%, rgba(4, 0, 11, 0.7847514005602241) 100%);
    border-radius: 20px 50px;
    padding: 1px;
    padding-top: 3px;
}

.reklam h6 {
    color: rgb(8, 8, 8);
}

.reklam button {
    border: 0px;
    background: rgba(255, 228, 196, 0);
    padding-bottom: 3px;
}


.popup-content {
    position: absolute;
    z-index: 99999!important;
    pointer-events: auto;
    text-align: end !important;
    top: 1px !important;
    left: 0px !important;
    /* left: 319.266px; */
    width: 100% !important;
    background: #000000d0 !important;
    height: 100% !important;
}

lightboxBackdrop {
    padding-top: 200px;

}

.vjs-poster {
    width: 50% !important;
    height: 50% !important;
}

.kapat {
    position: absolute;
    top: 5px;
    right: 10px; background: #E0BC69;
}
.kapat a{
    text-decoration: none;
    padding: 7px;
    color:white;
}

.player-wrapper {
    position: relative;
    padding-top: 50.25%
        /* Player ratio: 100 / (1280 / 720) */
}