.foot img{
    margin-top: 20px;
    
    float: left;
   
    margin-bottom: 15px;
}
@media only screen and (max-width: 500px) {
  .foot img{
    margin-top: 25px;
    
    float: left;
   width: 100%;
    margin-bottom: 15px;
}
}
.foott img{
  margin-top: 20px;
  float: left;
  padding-left: 40px;
  margin-bottom: 15px;
}
@media only screen and (min-width: 1000px) {
    .footermob {
      display: none;
    }
  }
  @media only screen and (max-width: 999px) {
    .footermob {
      background: #E0BC69;
    }
  }