@media only screen and (min-width: 1000px) {
    .navmabil {
    display:none;
  
    }
  }
  .navmabil{
    position: absolute;
    right: 0px;
   top:15px;
    
    z-index: 100;
    
  }
  .fade{
    background: #E0BC69!important;
  }
  .justify-content-end h1{
    
    text-align: center;
    color: rgb(24, 23, 23);
    font-family: Cambria;
    
  }
  .pe-3 {
    padding-right: 0rem!important;
}

.resimbir{
  margin-top: 20px;
  width: 85%;
}

  .justify-content-end{
    padding-top: 91px;
  }
  .offcanvas{
    background: #E0BC69!important;
  }
  .navbar-light .navbar-toggler{
    color: #E0BC69!important;
    border-color: #E0BC69!important;
   
    z-index: 9999;
  }
  .bg-light{
    --bs-bg-opacity: 1;
    background-color: rgba(0, 0, 0, 0)!important;
  }
  
  .dilsecenek{
    padding-top: 40px;
  }
  .dilsecenek h6{
    color: black;
  }
  
