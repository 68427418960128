.swiper {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 999px) {
    .mySwiper img {
      object-fit: cover;
      height: 300px;
      width: 100%;;
     
      }
  }
 
  @media only screen and (min-width: 1000px) {
    .mySwiper img {
      object-fit:initial ;
      height: 100vh;
      width: 100%;
      padding-top: 60px;
      bottom: 0;
      /* position: absolute; */
      }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  
  
 
  

 
  
