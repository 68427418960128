
.plan h6{
    color: white;
}
.kat{ 
    margin: 400px;
}
.katlar li{
color: #E0BC69;
list-style-type:none;
text-align: left;
}
.resimmobil img{
  width: 80%;
  height: 80%!important;
}

   .resim img{
     width: 80%;
     animation-name: katresim;
  animation-duration: 4s;
   }
   @keyframes katresim {
    from {opacity: 0.2;}
    to {opacity: 1;}
  }
   
   @media only screen and (min-width: 1000px) {
    .dd{
      padding-top: 10% !important;
      
    }
  }
  @media only screen and (max-width: 999px) {
    .dd{
      display: none;
      
    }
  }
  @media only screen and (min-width: 999px) {
    .ddmobil{
      display: none;
    }
  }
  .daierler li{
    list-style-type: none;
  }
  
   @media only screen and (min-width: 1000px) {
    .daierler{
      padding-top: 9%;
    }
  }
  @media only screen and (min-width: 1000px) {
    .resim{
      padding-top: 5%;
     }
  }
  @media only screen and (min-width: 1000px) {
    .sol{
      padding-top: 19%;
     }
  }
  @media only screen and (max-width: 1000px) {
    .hepsi{
      object-fit: cover;
      height: 100%;
      width: 100%;
      bottom: 0;
      /* position: absolute; */
      top: 0;
    
      background: url('../../../public/images/mobil-bg.jpg');
  
  
     }
  }
  @media only screen and (min-width: 1000px) {
  .katresimsayfa{
    width: 15%;
    position: absolute;
    left: 41px;
    right: 0;
    
    margin-right: auto;
    top: 9%;
  }}
  
  @media only screen and (min-width: 1000px) {
    .hepsi{
      object-fit: cover;
      height: 100vh;
      width: 100%;
      bottom: 0;
      /* position: absolute; */
      top: 0;
    
      background: url('../../../public/images/bg.jpg');
  
  
     }
  }
   
   
  
  
   
   .daierler p{
     color: white;
   }
  .accordion-button{
    background: #29231c;
  }
  
  .daierler a{
    text-decoration: none;
    color: black;
  }
  .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #29231c;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.katlar button{
  background: rgba(80, 80, 80, 0);
  color: #E0BC69;
  border: 0px;
  padding: 5px;
}
.daierler button {
  position: relative;
  display: list-item;
  align-items: center;
  width: 100%;
  padding: 4px;
  font-size: 1rem;
  color: #E0BC69;
 
  text-align: center;
  background-color: #ff162900;
  border: 0px; 
 
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}


.mobilkatp{
 margin-top: 15%;
 font-family: Mulish;
 color: white;
 padding-bottom: 20px;
}
.daierlermobil button{
  position: relative;
  
  align-items: center;
  width: 100%;
  padding: 4px;
  font-size: 1rem;
  color: #E0BC69;
 
  text-align: center;
  background-color: #ff162900;
  border: 0px; 
 
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}
.daierlermobil li{
  list-style-type: none;
}


.ReactGridGallery_tile-viewport{
  width: 100%!important;
  height: 100%!important;
}

.ReactGridGallery_tile{
  margin: 2px!important;
  user-select: none!important;
  position: relative!important;
  float:initial!important;
  background: rgba(0, 0, 0, 0)!important;
  padding: 0px!important;
}
@media only screen and (min-width: 999px) {
  .ReactGridGallery_tile-viewport img{
    height: 450px!important;
  }
}

.deneee img{
  width: 500px!important;
  height: 500px!important;
}
